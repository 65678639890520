import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import SEO from 'components/seo'
import styled from 'styled-components'
import Img from 'gatsby-image'
import colors from 'styles/tailwind/colors'
import screens from 'styles/tailwind/screens'
import { FormattedMessage } from 'react-intl'
import WithIntl from 'components/WithIntl'
import { lifecycle } from 'recompose'

const Mounted = lifecycle({
  componentDidMount() {
    this.setState({ mounted: true })
  },
})(({ mounted, children }) => <>{(mounted && children) || null}</>)

const StyledSection = styled.section`
  ${({ bgImg = null }) =>
    bgImg &&
    `
    background-image: url(${bgImg});
    background-size: cover;
    background-position: 50% 100%;
  `}

  ${({ height = null }) =>
    height &&
    `
    height: ${height};
  `}
`

const StyledHeading = styled.h2`
  display: flex;
  align-items: center;
  justify-items: center;
  text-transform: uppercase;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 3px;
    background-color: ${colors.primary};
    flex-grow: 1;

    @media (min-width: ${screens.lg}) {
      height: 5px;
    }
  }

  &::before {
    margin-right: 0.5em;
  }

  &::after {
    margin-left: 0.5em;
  }

  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`

const StyledServiceListItem = styled.li``

const GoogleMapEmbedWrapper = styled.div`
  iframe {
    width: 100%;
  }
`

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 0;
  height: ${props => props.height || 'auto'} !important;

  & > picture, img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${props =>
      props.fit || 'cover'} !important; object-position: ${props =>
  props.position || '50% 50%'} !important;'
  }
`

const IndexPage = ({
  intl: { locale },
  data: { pageData = {}, hero, redbg },
}) => (
  <>
    <SEO title={pageData.meta.title} lang={locale} keywords={[]} />
    <Helmet titleTemplate="%s" />

    <main className="overflow-hidden flex-grow">
      <StyledSection
        id="intro"
        className="relative flex items-center justify-center"
        height="40vh"
        // bgImg={HeroImg}
      >
        <h1
          className="text-center text-white text-2xl xl:text-3xl relative z-10"
          dangerouslySetInnerHTML={{ __html: pageData.intro.primaryCopy }}
        />

        <BgImg
          className="sm:hidden"
          fluid={hero.sharp.fluidXS}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden sm:block md:hidden"
          fixed={hero.sharp.fixedSM}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden md:block lg:hidden"
          fixed={hero.sharp.fixedMD}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden lg:block xl:hidden"
          fixed={hero.sharp.fixedLG}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden xl:block"
          fixed={hero.sharp.fixedXL}
          height="100%"
          position="50% 100%"
        />
      </StyledSection>

      <StyledSection
        className="flex items-center justify-center px-6 py-10 text-center"
        dangerouslySetInnerHTML={{
          __html: pageData.intro.secondaryCopyMd.remark.html,
        }}
      />

      <StyledSection id="services" className="bg-grey-light p-6 lg:py-12">
        <StyledHeading
          className="heading text-center"
          dangerouslySetInnerHTML={{ __html: pageData.services.heading }}
        />

        <ol className="list-reset flex flex-wrap lg:px-10">
          {pageData.services.servicesMd &&
            pageData.services.servicesMd.map(
              ({ remark: { html, id } }, idx) => (
                <li
                  key={id}
                  className={`block ${idx === 0 ? `` : `mt-4`}`}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              )
            )}
        </ol>
      </StyledSection>

      <StyledSection id="activity" className="p-6 lg:py-12">
        <StyledHeading
          className="heading text-center"
          dangerouslySetInnerHTML={{ __html: pageData.activity.heading }}
        />

        <div
          className="text-center lg:px-10"
          dangerouslySetInnerHTML={{
            __html: pageData.activity.copyMd.remark.html,
          }}
        />
      </StyledSection>

      <StyledSection className="bg-grey-light flex flex-wrap overflow-hidden sm:mx-0 sm:flex-row-reverse">
        <div className="w-full overflow-hidden sm:my-10 xl:my-16 sm:px-0 sm:w-1/2 md:px-10">
          {pageData.sideImage.image.sharp && (
            <>
              <Img
                className="sm:hidden -mx-6 -mt-6 mb-4"
                fluid={pageData.sideImage.image.sharp.fluid414}
              />
              <Img
                className="hidden sm:block md:hidden rounded-lg mx-auto"
                fixed={pageData.sideImage.image.sharp.fixed200}
              />
              <Img
                className="hidden md:block xl:hidden rounded-lg mx-auto"
                fixed={pageData.sideImage.image.sharp.fixed300}
              />
              <Img
                className="hidden xl:block rounded-lg mx-auto"
                fixed={pageData.sideImage.image.sharp.fixed400}
              />
            </>
          )}
        </div>
        <div className="w-full overflow-hidden sm:my-10 xl:my-16 sm:px-0 sm:w-1/2 md:px-10">
          <div
            className="px-6 sm:px-8 md:px-4 lg:px-16"
            dangerouslySetInnerHTML={{
              __html: pageData.sideImage.copyMd.remark.html,
            }}
          />
        </div>
      </StyledSection>

      <StyledSection className="flex flex-wrap overflow-hidden sm:mx-0">
        <div className="w-full overflow-hidden sm:my-10 xl:my-16 sm:px-0 sm:w-1/2 md:px-10">
          {pageData.sideImageAlt.image.sharp && (
            <>
              <Img
                className="sm:hidden -mx-6 -mt-6 mb-4"
                fluid={pageData.sideImageAlt.image.sharp.fluid414}
              />
              <Img
                className="hidden sm:block md:hidden rounded-lg mx-auto"
                fixed={pageData.sideImageAlt.image.sharp.fixed200}
              />
              <Img
                className="hidden md:block xl:hidden rounded-lg mx-auto"
                fixed={pageData.sideImageAlt.image.sharp.fixed300}
              />
              <Img
                className="hidden xl:block rounded-lg mx-auto"
                fixed={pageData.sideImageAlt.image.sharp.fixed400}
              />
            </>
          )}
        </div>

        <div className="w-full overflow-hidden sm:my-10 xl:my-16 sm:px-0 sm:w-1/2 md:px-10">
          <div
            className="px-6 sm:px-8 md:px-4 lg:px-16"
            dangerouslySetInnerHTML={{
              __html: pageData.sideImageAlt.copyMd.remark.html,
            }}
          />
        </div>
      </StyledSection>

      <StyledSection
        id="legislation"
        className="relative text-center px-6 py-10 md:p-16"
      >
        <div
          className="text-center text-white relative z-10"
          dangerouslySetInnerHTML={{
            __html: pageData.legislation.copyMd.remark.html,
          }}
        />

        <BgImg
          className="sm:hidden"
          fluid={redbg.sharp.fluidXS}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden sm:block md:hidden"
          fixed={redbg.sharp.fixedSM}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden md:block lg:hidden"
          fixed={redbg.sharp.fixedMD}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden lg:block xl:hidden"
          fixed={redbg.sharp.fixedLG}
          height="100%"
          position="50% 100%"
        />
        <BgImg
          className="hidden xl:block"
          fixed={redbg.sharp.fixedXL}
          height="100%"
          position="50% 100%"
        />
      </StyledSection>

      <StyledSection id="references" className="p-6 lg:py-12">
        <StyledHeading
          className="heading text-center"
          dangerouslySetInnerHTML={{ __html: pageData.references.heading }}
        />

        <div
          className="lg:px-10"
          dangerouslySetInnerHTML={{
            __html: pageData.references.copyMd.remark.html,
          }}
        />
      </StyledSection>

      <StyledSection id="contact" className="p-6 lg:py-12">
        <StyledHeading
          className="heading text-center"
          dangerouslySetInnerHTML={{ __html: pageData.contact.heading }}
        />

        <div className="flex flex-wrap -mx-2 overflow-hidden lg:-mx-4">
          <div className="flex flex-col justify-between my-2 px-2 w-full overflow-hidden md:w-3/5 lg:my-4 lg:px-4">
            <address className="flex flex-wrap -mx-2 overflow-hidden roman">
              <div className="my-2 px-2 w-1/2 overflow-hidden">
                <div className="mb-2">
                  <FormattedMessage id="contact.address">
                    {content => (
                      <span className="font-bold block sm:inline">
                        {content}
                      </span>
                    )}
                  </FormattedMessage>
                  {` `}
                  {pageData.contact.address}
                </div>
                <div className="mb-2">
                  <FormattedMessage id="contact.phone">
                    {content => (
                      <span className="font-bold block sm:inline">
                        {content}
                      </span>
                    )}
                  </FormattedMessage>
                  {` `}
                  {pageData.contact.phone}
                </div>
              </div>

              <div className="my-2 px-2 w-1/2 overflow-hidden">
                <div className="mb-2">
                  <FormattedMessage id="contact.email">
                    {content => (
                      <span className="font-bold block sm:inline">
                        {content}
                      </span>
                    )}
                  </FormattedMessage>
                  {` `}

                  <Mounted>
                    <div
                      className="inline-block"
                      dangerouslySetInnerHTML={{
                        __html: pageData.contact.emailMd.remark.html,
                      }}
                    />
                  </Mounted>
                </div>
                <div className="mb-2">
                  <FormattedMessage id="contact.web">
                    {content => (
                      <span className="font-bold block sm:inline">
                        {content}
                      </span>
                    )}
                  </FormattedMessage>
                  {` `}
                  <div
                    className="inline-block"
                    dangerouslySetInnerHTML={{
                      __html: pageData.contact.webMd.remark.html,
                    }}
                  />
                </div>
              </div>
            </address>

            <div className="flex flex-wrap justify-between content-center bg-grey-light rounded-lg p-4 mb-4">
              <div className="xl:inline mb-2 xl:mb-0">
                <FormattedMessage id="contact.manager">
                  {content => (
                    <span className="font-bold block sm:inline">{content}</span>
                  )}
                </FormattedMessage>
                {` `}
                {pageData.contact.manager.name}
              </div>
              <span className="hidden xl:inline-block">&nbsp;</span>
              <div className="xl:inline">
                <FormattedMessage id="contact.managerPhone">
                  {content => (
                    <span className="font-bold block sm:inline">{content}</span>
                  )}
                </FormattedMessage>
                {` `}
                {pageData.contact.manager.phone}
              </div>
            </div>

            {pageData.meta.keywords && (
              <div className="kwords flex flex-row">
                <h4 className="whitespace-no-wrap">
                  <FormattedMessage id="keywords" />:
                </h4>
                <div className="flex-grow ml-2">
                  {pageData.meta.keywords.join(`, `)}
                </div>
              </div>
            )}
          </div>

          <div className="my-2 px-2 w-full overflow-hidden md:w-2/5 lg:my-0 lg:px-4">
            <GoogleMapEmbedWrapper
              className="google-map-embed"
              dangerouslySetInnerHTML={{
                __html: pageData.contact.googleMapsEmbed,
              }}
            />
          </div>
        </div>
      </StyledSection>
    </main>
  </>
)

export default WithIntl(IndexPage)

export const markdownRemarkHtmlFragment = graphql`
  fragment MarkdownRemarkHtmlFragment on homeYamlTextNode {
    remark: childMarkdownRemark {
      id
      html
    }
  }
`

export const markdownRemarkHtmlAstFragment = graphql`
  fragment MarkdownRemarkHtmlAstFragment on homeYamlTextNode {
    remark: childMarkdownRemark {
      id
      htmlAst
    }
  }
`

export const homeOptimizedImagesFragment = graphql`
  fragment HomeOptimizedImagesFragment on Query {
    hero: file(
      relativePath: { eq: "heroimg.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      sharp: childImageSharp {
        fixedXL: fixed(width: 1200, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fixedLG: fixed(width: 992, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fixedMD: fixed(width: 768, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fixedSM: fixed(width: 576, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fluidXS: fluid(maxWidth: 414, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    redbg: file(
      relativePath: { eq: "redbg.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      sharp: childImageSharp {
        fixedXL: fixed(width: 1200, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fixedLG: fixed(width: 992, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fixedMD: fixed(width: 768, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fixedSM: fixed(width: 576, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }

        fluidXS: fluid(maxWidth: 414, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export const homepageQuery = graphql`
  query homeQuery($lang: String!) {
    ...HomeOptimizedImagesFragment

    pageData: homeYaml(fields: { lang: { eq: $lang } }) {
      fields {
        lang
      }

      meta {
        title
        keywords
      }

      intro {
        primaryCopy
        secondaryCopyMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      services {
        heading
        servicesMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      activity {
        heading
        copyMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      sideImage {
        image {
          sharp: childImageSharp {
            fixed400: fixed(width: 400, height: 400, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fixed200: fixed(width: 200, height: 200, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fixed300: fixed(width: 300, height: 300, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid414: fluid(
              maxWidth: 414
              maxHeight: 207
              cropFocus: CENTER
              quality: 85
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        copyMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      sideImageAlt {
        image {
          sharp: childImageSharp {
            fixed400: fixed(width: 400, height: 400, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fixed200: fixed(width: 200, height: 200, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fixed300: fixed(width: 300, height: 300, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fluid414: fluid(
              maxWidth: 414
              maxHeight: 207
              cropFocus: CENTER
              quality: 85
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        copyMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      legislation {
        copyMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      references {
        heading

        copyMd {
          ...MarkdownRemarkHtmlFragment
        }
      }
      contact {
        heading
        address
        phone
        emailMd {
          ...MarkdownRemarkHtmlFragment
        }
        webMd {
          ...MarkdownRemarkHtmlFragment
        }
        manager {
          name
          phone
        }
        googleMapsEmbed
      }
    }
  }
`
